import React from 'react';
import { ListView, PullToRefresh, SearchBar, WingBlank } from 'antd-mobile';
import moment from 'moment';
import request from '../../Utils/request';
import TopBar from './../TopBar';
import { getSupplierCode } from './../../Utils/mySupplier';

const dateFormat = 'YYYY-MM-DD';
const pageSize = 20;
let pageIndex = 1;

/**
 * 库存打印
 */
class StockList extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            hasMore: false,
            dataList: [],
            billNo: '',
        };
    }

    /**
     * 获取列表
     * @param {*} pIndex 
     */
    genData(pIndex = 1) {
        const { billNo = '' } = this.state;
        let supplierId = getSupplierCode();
        
        request({
            url: '/api/services/app/K3/GetStockInventoryListByPage',
            method: 'GET',
            params: {
                SkipCount: (pIndex - 1) * pageSize,
                MaxResultCount: pageSize,
                BillNo: billNo,
                SupplierId: supplierId,
            }
        }).then(res => {
            const list = this.state.dataList.concat(res.items);
            //console.log(res.totalCount);
            //console.log((pageIndex * pageSize));
            this.setState({
                hasMore: res.totalCount > (pageIndex * pageSize),
                dataList: list,
                dataSource: this.state.dataSource.cloneWithRows(list),
                isLoading: false,
            })
        });
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });

        this.genData();
    }

    /**
     * 加载下一页数据
     * @param {*} event 
     */
    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }

        if (this.state.hasMore === false) {
            return;
        }

        console.log('reach end', event);
        this.setState({ isLoading: true });
        this.genData(++pageIndex);
    }

    //下拉刷新
    onRefresh = () => {
        pageIndex = 1;

        this.setState({
            billNo: '',
            dataList: [],
            isLoading: true,
        });

        this.genData();
    }

    /**
     * 跳转到采购订单详情页面
     * @param {*} row 
     */
    onPrint = (row) => {
        //console.log('view...');
        this.props.history.push('/Stock/Print/' + row.id);
    }

    /**
     * 搜索采购订单
     */
    onSearch = (value) => {
        //console.log(value);
        if (value && value.length > 0) {
            pageIndex = 1;
            const that = this;

            this.setState({
                billNo: value,
                dataList: [],
                isLoading: true,
            }, function () {
                that.genData();
            });
        }
    }

    /**
     * 
     */
    onCancel = () => {
        pageIndex = 1;
        const that = this;

        this.setState({
            billNo: '',
            dataList: [],
            isLoading: true,
        }, function () {
            that.genData();
        });
    }


    render() {
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 8,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );
        const row = (rowData, sectionID, rowID) => {
            return (
                <div key={rowID} style={{ padding: '0 15px' }} item={rowData} onClick={() => this.onPrint(rowData)}>
                    <div style={{ display: '-webkit-box', display: 'flex', justifyContent: 'space-between', padding: '15px 0' }}>
                        <div style={{ lineHeight: 1 }}>
                            <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>单据编号：{rowData.billNo}</div>
                            <div style={{ marginBottom: '8px', }}>物料编码：{rowData.materialNumber}</div>
                            <div style={{ marginBottom: '8px', }}>物料名称：{rowData.materialName}</div>
                            <div style={{ marginBottom: '8px', }}>库存：{rowData.qty}</div>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <WingBlank>
                <TopBar title="打印订单-列表" {...this.props} />
                <SearchBar placeholder="请输入订单编号" onSubmit={this.onSearch} />
                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    //renderHeader={() => <span>111</span>}
                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? 'Loading...' : '我是有底线的'}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={20}
                    useBodyScroll
                    onScroll={() => { console.log('scroll'); }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                    pullToRefresh={<PullToRefresh
                        onRefresh={this.onRefresh}
                    />}
                />
            </WingBlank>)
    }
}

export default StockList;