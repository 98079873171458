import request from "./../Utils/request";

/**
 *
 * @param {*} params
 */
export async function getMySuppliers(params) {
  return new Promise((resolve, reject) => {
    return request({
      url: "/api/services/app/K3/GetMySuppliers",
      method: "GET",
      params: params,
    })
      .then((res) => {
        // mySuppliers = res.items;
        // const nums = mySuppliers.length;
        // setMySuppliers(mySuppliers);

        // this.autoHereWeGo(mySuppliers);

        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 *
 * @returns
 */
export async function getMySuppliersFromLocal() {
  return new Promise((resolve, reject) => {
    let suppliers = [];
    suppliers.push();
    suppliers.push({
      supplierId: 18013,
      supplierNumber: "SUPPLIER001",
      supplierName: "采购测试供应商",
    });

    resolve(suppliers);
  });
}

/**
 *
 * @param {*} params
 * @returns
 */
export async function getPurOrderListByPage(params) {
  return request({
    url: "/api/services/app/Print/GetPurchaseOrderListByPage",
    method: "GET",
    params: params,
  });
}

/**
 * 获取中台采购提货单
 * @param {*} params
 */
export async function getPickOrderListByPage(params) {
  return request({
    url: "/api/services/app/Trantor/GetPickOrderListByPage",
    method: "GET",
    params: params,
  });
}

/**
 * 中台提货单详情
 * @returns
 */
export async function getPickOrder(params) {
  return request({
    url: "/api/services/app/Trantor/GetPickOrder",
    method: "GET",
    params: params,
  });
}

/**
 * 中台提货单明细详情
 * @returns
 */
export async function getPickOrderLine(params) {
  return request({
    url: "/api/services/app/Trantor/GetPickOrderLine",
    method: "GET",
    params: params,
  });
}

/**
 *
 * @returns
 */
export async function getTransferOrderListByPage(params) {
  return request({
    url: "/api/services/app/Trantor/GetTransferAllocationOrderListByPage",
    method: "GET",
    params: params,
  });
}

/**
 * 
 * @param {*} params 
 * @returns 
 */
export async function getTransferOrderDetail(params) {
  return request({
    url: "/api/services/app/Trantor/GetTransferAllocationOrderDetail",
    method: "GET",
    params: params,
  });
}

/**
 * 
 * @param {*} params 
 * @returns 
 */
export async function getTransferOrderLineDetail(params)
{
  return request({
    url: "/api/services/app/Trantor/GetTransferOrderLineDetail",
    method: "GET",
    params: params,
  });
}

/**
 *
 * @returns
 */
export async function getAppList() {
  return request({
    url: "/api/services/app/Print/GetPrintAppList",
    method: "GET",
  });
}

/**
 *
 * @returns
 */
export async function getPrintSeedListByPage(params) {
  return request({
    url: "/api/services/app/Print/GetPrintSeedListByPage",
    method: "GET",
    params: params,
  });
}

/**
 * 金蝶物料信息-维莎
 * @param {*} params 
 * @returns 
 */
export async function getMaterialInfoFromK3(params)
{
  return request({
    url:'/api/services/app/Print/QueryMaterialFromK3',
    method:'GET',
    params:params
  });
}