import React from 'react';
import { NavBar, WhiteSpace, Icon, Popover, Modal } from 'antd-mobile';

const Item = Popover.Item;
const alert = Modal.alert;

/**
 * 
 */
export default class TopBar extends React.Component {

    /**
     * 当点击更多按钮时
     * @param {*} opt 
     */
    onSelect = (opt) => {
        let key = opt.props.value;

        switch (key) {
            case 'home': this.props.history.push('/App'); break;
            case 'switch': this.props.history.push('/MySuppliers'); break;
            case 'signout':
                alert('', '您确定要退出么？', [
                    { text: '取消', onPress: () => console.log('cancel') },
                    {
                        text: '确定', onPress: () => {
                            localStorage.clear();

                            setTimeout(() => {
                                this.props.history.push('/Login')
                            }, 1000);
                        }
                    },
                ])
                break;
        }
    };

    render() {
        const { title = '标题', showback = true, showSupplierSelector = false, history } = this.props;
        return (
            <div style={{ marginTop: "10px" }}>
                {/* <div>
                    <img src="/assets/logo.png" style={{ width: "90px", }} />
                </div> */}
                <NavBar
                    mode="light"
                    icon={showback && (<Icon type="left" />)}
                    onLeftClick={() => showback === true ? history.goBack() : console.log('stop...')}
                    rightContent={<Popover
                        overlayClassName="fortest"
                        overlayStyle={{ color: 'currentColor' }}
                        overlay={[
                            (<Item key="3" value="home" >首页</Item>),
                            showSupplierSelector && (<Item key="4" value="switch" >切换工厂</Item>),
                            (<Item key="5" value="signout" >退出</Item>),
                        ]}
                        align={{
                            overflow: { adjustY: 0, adjustX: 0 },
                            offset: [-10, 0],
                        }}
                        onVisibleChange={this.handleVisibleChange}
                        onSelect={this.onSelect}>
                        <div style={{
                            height: '100%',
                            padding: '0 15px',
                            marginRight: '-15px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        >
                            <Icon type="ellipsis" />
                        </div>
                    </Popover>}
                >{title}</NavBar>
            </div>
        )
    }
}

