import React from 'react';
import { WingBlank, Grid, Icon, Toast } from 'antd-mobile';
import './App.css';
import request from './Utils/request';
import { fetchMySuppliers, updateMySuppliers, getSupplierCode, clear, setSupplierCode } from './Utils/mySupplier';
import { getAppList, } from './Utils/httpApiHelper';
// import logo from './logo.svg';

/**
 * 
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        // { icon: '/assets/pandian.svg', text: `采购订单`, linkUrl: '/PurchaseOrder/List', },
        // //{ icon: '/assets/raw.svg', text: `油漆工厂`, linkUrl: '/Stock/List' }, { icon: '', text: ``, linkUrl: '' },
        // { icon: '/assets/truck.svg', text: `提货单`, linkUrl: '/PickOrder/List', autoGo: true, }, { icon: '', text: ``, linkUrl: '' }, { icon: '', text: ``, linkUrl: '' },
        // { icon: '', text: ``, linkUrl: '' }, { icon: '', text: ``, linkUrl: '' }, { icon: '', text: ``, linkUrl: '' },
      ]
    };
  }

  componentDidMount() {
    this.handleGetMyAppList();
    this.handleGetMySuppliers();
  }

  handleGetMyAppList() {
    const that = this;
    getAppList().then(res => {
      this.setState({
        data: res,
      });
    });
  }

  /**
   * 
   */
  handleGetMySuppliers = () => {
    let mySuppliers = fetchMySuppliers();
    let supplierCode = getSupplierCode();
    let nums = mySuppliers.length;
    const that = this;

    mySuppliers = [];

    if (mySuppliers === null || nums == 0) {
      request({
        url: '/api/services/app/Print/GetMyPurSuppliers',
        method: 'GET',
        params: {}
      }).then(res => {
        mySuppliers = res.items;
        const nums = mySuppliers.length;
        updateMySuppliers(mySuppliers);
      });
    }
  }

  /**
   * 跳转
   * @param {*} suppliers 
   */
  autoHereWeGo = (suppliers) => {
    const nums = suppliers.length;

    if (nums === 0) {

    } else if (nums === 1) {
      //setCurrentSupplierId(suppliers[0].supplierId);
      setSupplierCode(suppliers[0].supplierCode);
    } else if (nums > 1) {
      this.props.history.push('/MySuppliers');
    }
  }

  onGoTo = (el, index) => {
    // console.log(el);
    // console.log(index);

    const { linkUrl = '', autoGo = false, } = el;
    let suppplierCode = getSupplierCode();
    const mySuppliers = fetchMySuppliers();
    const nums = mySuppliers.length;

    //console.log(el);

    if (linkUrl && linkUrl != '') {
      if (autoGo === true) {
        this.props.history.push(linkUrl);
      }
      else if (suppplierCode && suppplierCode.length > 0) {
        this.props.history.push(linkUrl);
      }
      else {
        suppplierCode = mySuppliers[0].supplierCode;
        setSupplierCode(suppplierCode);
        this.props.history.push(linkUrl);
      }
    }
  }

  render() {
    const { data = [] } = this.state;

    return (
      <WingBlank>
        <p style={{ fontSize: "30px", color: "#FFFFFF", textAlign: 'center' }}>上海朵艺标签打印</p>
        <Grid data={data} columnNum={3} onClick={this.onGoTo} />
      </WingBlank>
    );
  }
}

export default App;
