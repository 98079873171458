import React from 'react';
import { WingBlank, List, SearchBar } from 'antd-mobile';
import request from './../../Utils/request';
import { fetchMySuppliers, updateMySuppliers, setSupplierCode, clear } from './../../Utils/mySupplier';

const Item = List.Item;
const Brief = Item.Brief;
/**
 * 我的供应商选择器
 */
export default class SupplierSelector extends React.Component {
    state = {
        keyWords: '',
        suppliers: [],
        isLoading: false,
    };

    componentDidMount() {
        this.handleGetMySuppliers();
    }

    /**
     * 获取中台采购供应商列表
     */
    handleGetMySuppliers = (kw) => {
        const { keyWords = '' } = this.state;
        let mySuppliers = fetchMySuppliers();
        mySuppliers = [];

        if (mySuppliers === null || mySuppliers.length == 0) {
            request({
                url: '/api/services/app/Print/GetMyPurSuppliers',
                method: 'GET',
                params: { supplierCode: keyWords }
            }).then(res => {
                mySuppliers = res.items;
                const nums = mySuppliers.length;

                updateMySuppliers(mySuppliers);

                this.setState({
                    suppliers: mySuppliers,
                    isLoading: false,
                });
            });
        } else if (mySuppliers.length === 1) {
            setSupplierCode(mySuppliers[0].supplierCode);
            this.props.history.push('/App');
        } else if (mySuppliers.length > 1) {
            this.setState({
                suppliers: mySuppliers,
                isLoading: false,
            });
        }
    }

    renderListItems = () => {
        const { suppliers = [], } = this.state;
        const listItems = suppliers.map(sup => {
            return (<Item>{sup.supplierName}</Item>);
        });
    }

    /**
     * 
     * @param {*} supplier 
     */
    onSelect = (supplier) => {
        if (supplier && supplier.supplierId > 0) {
            setSupplierCode(supplier.supplierCode);
            this.props.history.push('/PickOrder/List');
        }
    }

    onSearch = (value) => {
        const that = this;

        if (value && value != '') {
            that.setState({
                keyWords: value
            }, function () {
                that.handleGetMySuppliers();
            });
        }
    }

    render() {
        const { suppliers = [], } = this.state;

        return (
            <WingBlank>
                <List renderHeader={() => (<span style={{ color: "#FFFFFF", fontSize: "18px", fontWeight: "Bold" }}>请选择工厂</span>)}>
                    <Item>
                        <SearchBar placeholder="请输入供应商编码/名称" onSubmit={this.onSearch} />
                    </Item>
                    {suppliers && suppliers.map(sup => {
                        return (<Item key={sup.supplierCode} arrow="horizontal" onClick={() => this.onSelect(sup)}>
                            {sup.supplierCode}<Brief>{sup.supplierName}</Brief>
                        </Item>);
                    })}
                </List>
            </WingBlank>)
    }
}