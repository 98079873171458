import React from "react";
import {
  WingBlank,
  WhiteSpace,
  List,
  Button,
  DatePicker,
  InputItem,
  Modal,
  Toast,
} from "antd-mobile";
import moment, { max } from "moment";
import TopBar from "../TopBar";
import { getMaterialInfoFromK3 } from "./../../Utils/httpApiHelper";

const Brief = List.Item.Brief;
const dateFormat = "YYYY-MM-DD";
const alert = Modal.alert;

// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp("\\biPhone\\b|\\biPod\\b", "i").test(
  window.navigator.userAgent
);
let moneyKeyboardWrapProps;
if (isIPhone) {
  moneyKeyboardWrapProps = {
    onTouchStart: (e) => e.preventDefault(),
  };
}

/**
 * 模拟维莎补打模板-
 */
class MockOrderPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      billNo: "",
      batchNumber: "",
      skuNo: "",
      skuName: "",
      nums: 0,
      buildDate: new Date(),
      supplierCode: "",
      checkerCode: "WS1",

      remainStockQty: 0,
      //orderLine: {},
      //qcUsers: [],
      //showQCSelector: false,
    };
  }

  componentDidMount() {}

  onChangeSupplierCode = (val) => {
    console.log(val);

    this.setState({
      supplierCode: val,
      billNo: val,
    });
  };

  onChangeBatchNo = (val) => {
    this.setState({
      batchNumber: val,
    });
  };

  onChangeParcelCode = (val) => {
    this.setState({
      skuNo: val,
    });
  };

  onBlurParcelCode = () => {
    const { skuNo = "" } = this.state;
    if (skuNo != "") {
      getMaterialInfoFromK3({
        materialNo: skuNo,
      })
        .then((res) => {
          this.setState({
            skuName: res,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            skuName: "",
          });
        });
    } else {
      this.setState({
        skuName: "",
      });
    }
  };

  /**
   * 修改打印数量
   * @param {*} val
   */
  onChangeQty = (val) => {
    //console.log(val);

    this.setState({
      nums: val,
    });
  };

  /**
   * 选择生产日期
   * @param {*} date
   */
  onChangeBuildDate = (date) => {
    //console.log(date);
    this.setState({
      //buildDate: moment(date).format(dateFormat)
      buildDate: date,
    });
  };

  /**
   *
   */
  showAlert = () => {
    const {
      billNo = "",
      batchNumber = "",
      skuNo = "",
      skuName = "",
      nums = 0,
      buildDate = "",
      supplierCode = "",
      checkerCode = "",
      qcUsers = [],
      showQCSelector = false,
    } = this.state;
    //console.log(this.state);
    if (supplierCode === "") {
      Toast.fail("请输入工厂编码", 1);
      return;
    } else if (supplierCode.length != 4) {
      Toast.fail("工厂编码必须是4位", 1);
      return;
    } else if (batchNumber === "") {
      Toast.fail("批次号不能为空", 1);
      return;
    } else if (batchNumber.length != 5) {
      Toast.fail("批次号必须是5位", 1);
      return;
    } else if (checkerCode === "") {
      Toast.fail("请选择质检员", 1);
      return;
    } else if (nums <= 0) {
      Toast.fail("请输入打印数量", 1);
      return;
    } else if (nums <= 0) {
      Toast.fail("打印数量必须大于0", 1);
      return;
    } else {
      const alertInstance = alert(
        "提示",
        "您确定要打印" + nums + "个标签么？",
        [
          {
            text: "取消",
            onPress: () => console.log("cancel"),
            style: "default",
          },
          { text: "确定打印", onPress: () => this.doPrint() },
        ]
      );
    }
  };

  /**
   * 打印命令
   */
  doPrint = () => {
    const {
      billNo = "",
      batchNumber = "",
      skuNo = "",
      skuName = "",
      nums = 0,
      buildDate = "",
      supplierCode = "",
      checkerCode = "",
      qcUsers = [],
      showQCSelector = false,
    } = this.state;
    //console.log(this.state);

    if (supplierCode === "") {
      Toast.fail("请输入工厂编码", 1);
      return;
    } else if (supplierCode.length != 4) {
      Toast.fail("工厂编码必须是4位", 1);
      return;
    } else if (batchNumber == null || batchNumber === "") {
      Toast.fail("批次号不能为空", 1);
      return;
    } else if (batchNumber.length != 5) {
        Toast.fail("批次号只能是5位", 1);
        return;
    } else if (buildDate == null || buildDate === "") {
      Toast.fail("生产日期不能为空", 1);
      return;
    } else if (skuNo === null || skuNo === "") {
      Toast.fail("包件编码不能为空", 1);
      return;
    } else if (skuName === "") {
      Toast.fail("包件名称不能为空", 1);
      return;
    } else if (checkerCode == null || checkerCode === "") {
      Toast.fail("请选择质检员", 1);
      return;
    } else if (nums <= 0) {
      Toast.fail("请输入打印数量", 1);
      return;
    } else {
      //window.android.doPrint(billNo, materialNumber, materialName, remainStockQty, moment(buildDate).format(dateFormat), supplierNumber, checkerNumber, printDate);
      window.android.doPrint2(
        billNo.padEnd(15, "0"),
        skuNo,
        skuName,
        nums,
        moment(buildDate).format(dateFormat),
        supplierCode,
        checkerCode,
        batchNumber
      );
    }
  };

  render() {
    const {
      billNo = "",
      skuNo = "",
      skuName = "",
      nums = 0,
      buildDate = "",
      supplierCode = "",
      checkerCode = "",
      qcUsers = [],
      showQCSelector = false,
    } = this.state;
    return (
      <WingBlank>
        <TopBar title="维莎补码打印" {...this.props} />
        <List>
          <InputItem
            type="text"
            placeholder="请输入引入中台后的新代码"
            maxLength="4"
            onChange={this.onChangeSupplierCode}
          >
            工厂代码
          </InputItem>
          <InputItem
            type="money"
            placeholder="请输入批次号"
            maxLength="5"
            disabledKeys={["."]}
            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
            moneyKeyboardAlign="left"
            onChange={this.onChangeBatchNo}
          >
            批次号
          </InputItem>
          <List.Item extra={checkerCode}>
            <Brief></Brief>质检员
          </List.Item>
          <DatePicker
            mode="date"
            title="选择生产日期"
            extra=""
            value={buildDate}
            format={(value) => {
              return moment(value).format(dateFormat);
            }}
            onChange={this.onChangeBuildDate}
          >
            <List.Item arrow="horizontal">生产日期</List.Item>
          </DatePicker>
          <InputItem
            type="text"
            placeholder="请输入包件编码"
            maxLength="20"
            onChange={this.onChangeParcelCode}
            onBlur={this.onBlurParcelCode}
          >
            包件编码
          </InputItem>
          <List.Item>
            包件名称<Brief>{skuName}</Brief>
          </List.Item>
          <InputItem
            type="money"
            placeholder="请输入补打数量"
            disabledKeys={["."]}
            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
            moneyKeyboardAlign="right"
            value={nums}
            onChange={this.onChangeQty}
          >
            补打数量
          </InputItem>
          <WhiteSpace />
          <WhiteSpace />
        </List>
        <WhiteSpace />
        <WhiteSpace />
        <Button
          type="primary"
          style={{ background: "#4D2395" }}
          icon={<img src="/assets/print_2.svg" alt="" />}
          onClick={() => this.showAlert()}
        >
          打印
        </Button>
      </WingBlank>
    );
  }
}

export default MockOrderPrint;
