import { Toast } from 'antd-mobile';
import axios from 'axios';
import { createHashHistory } from 'history';

// const operation = Modal.operation;
const qs = require('qs');
const history = createHashHistory()

const request = axios.create({
  //baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

/**
 * request拦截器
 */
request.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem('duoyi.factory.token');

    if (token && token != null && token.length > 0) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    Toast.loading('加载中...', 0);

    //config.headers['Authorization'] = 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjMiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJBc3BOZXQuSWRlbnRpdHkuU2VjdXJpdHlTdGFtcCI6IjM1U0hGVkI1Qjc3TVFWNVBMVTJPNkc1TDc3Q0pNTUxQIiwiU2hvcElkIjoiMzM5MjgyNzEiLCJTaG9wTmFtZSI6IuadreW3nua7qOaxn-W6lyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiQWxsb3dBY2Nlc3NNaW5pU2hvcCI6IkZhbHNlIiwiUm9sZXNJblNob3BzIjoiW3tcIlNob3BJZFwiOjQyODY5NCxcIlNob3BOYW1lXCI6XCLkuIrmtbfmnb7msZ_lsZXljoVcIn0se1wiU2hvcElkXCI6NDI4Njk1LFwiU2hvcE5hbWVcIjpcIuWMl-S6rOWkp-WFtOWxleWOhVwifSx7XCJTaG9wSWRcIjoxOTI0MDI0OSxcIlNob3BOYW1lXCI6XCLkuIrmtbfpvpnkuYvmoqblsZXljoVcIn0se1wiU2hvcElkXCI6NDI4Njk2LFwiU2hvcE5hbWVcIjpcIuaIkOmDveaIkOWNjuW6l1wifSx7XCJTaG9wSWRcIjo0Mjg3MDAsXCJTaG9wTmFtZVwiOlwi5aSn6L-e5bqXXCJ9LHtcIlNob3BJZFwiOjQ0Nzc0NixcIlNob3BOYW1lXCI6XCLplb_mspnpm6joirHlupdcIn0se1wiU2hvcElkXCI6MTgwODE1NjUsXCJTaG9wTmFtZVwiOlwi5bm_5bee55m95LqR5bqXXCJ9LHtcIlNob3BJZFwiOjI4ODEzMDMzLFwiU2hvcE5hbWVcIjpcIuWMl-S6rOacnemYs-W6l1wifV0iLCJodHRwOi8vd3d3LmFzcG5ldGJvaWxlcnBsYXRlLmNvbS9pZGVudGl0eS9jbGFpbXMvdGVuYW50SWQiOiIyIiwic3ViIjoiMyIsImp0aSI6ImVmNjZkN2FlLTJmMDktNDQ2MS1iYzFmLTc4OWY3OThiNTRhYyIsImlhdCI6MTYwNjI3NjkzNCwibmJmIjoxNjA2Mjc2OTM0LCJleHAiOjE2MDYzNjMzMzQsImlzcyI6IkVSUCIsImF1ZCI6IkVSUCJ9.SNhTOkEZiZSMtVSgaoGb_Kd8Uzo8em43Sy05Qlmbg8o";
    config.headers['Abp.TenantId'] = 2;

    return config;
  },
  function (error) {
    Toast.hide();
    return Promise.reject(error);
  }
);

/**
 * response拦截器
 */
request.interceptors.response.use(
  response => {
    Toast.hide();
    return response.data.result;
  },
  error => {
    const { response = {} } = error;
    const { status, url } = response;

    Toast.hide();
    if (status === 403) {
      //router.push('/exception/403');
      return;
    }

    if (status === 401) {
      Toast.fail('用户未登录或登录已失效，请重新登录', 1);

      localStorage.clear();
      history.push('/Login');

      return Promise.reject(error);
    }

    if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
      Toast.fail(error.response.data.error.details, 3);
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {

      if (status === 401) {
        Toast.fail('用户未登录或登录已失效，请重新登录', 1);

        setTimeout(() => {
          localStorage.clear();
          history.push('/Login');
        }, 500);

        return Promise.reject(error);
      } else if (status === 500) {
        Toast.fail(error.response.data.error.message, 2);
        return Promise.reject(error.response.data.error.message);
      }
    } else if (!error.response) {
      Toast.fail('未知错误，请重试', 2);
    }
    else {
      if (status === 401) {
        this.props.history.push('/Login');
        return;
      }
    }

    return Promise.reject(error);
  }
);

export default request;







