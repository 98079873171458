import React from 'react';
import { WingBlank, WhiteSpace, List, Button, DatePicker, InputItem, Modal, Toast } from 'antd-mobile';
import moment from 'moment';
import request from '../../Utils/request';
import TopBar from './../TopBar';

const Brief = List.Item.Brief;
const dateFormat = 'YYYY-MM-DD';
const alert = Modal.alert;

/**
 * 打印模板
 */
export default class StockPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderEntry: {},
            qcUsers: [],
            showQCSelector: false,
        };
    }

    genData = () => {
        const { id = "" } = this.props.match.params;
        request({
            url: '/api/services/app/K3/GetStockInventory',
            method: 'GET',
            params: { id: id }
        }).then(res => {
            this.setState({
                orderEntry: res
            });
        });
    }

    /**
     * 
     */
    getQCUsers = () => {
        request({
            url: '/api/services/app/K3/GetQCUserListByPage',
            method: 'GET',
            params: { MaxResultCount: 500 }
        }).then(res => {
            this.setState({
                qcUsers: res.items
            });
        });
    }

    componentDidMount() {
        this.genData();
        this.getQCUsers();
    }

    /**
     * 修改打印数量
     * @param {*} val 
     */
    onChangeQty = (val) => {
        //console.log(val);

        this.setState({
            orderEntry: Object.assign(this.state.orderEntry, { 'qty': val })
        });
    }

    /**
     * 选择生产日期
     * @param {*} date 
     */
    onChangeBuildDate = (date) => {
        // console.log(date);
        this.setState({
            orderEntry: Object.assign(this.state.orderEntry, { 'buildDate': moment(date).format(dateFormat) })
        });
    }

    /**
     * 
     */
    showAlert = () => {
        const { orderEntry } = this.state;
        const { billNo = '', materialNumber = '', materialName = '', qty = 0, buildDate = '', supplierNumber = '', checkerNumber = '', printDate = '' } = orderEntry;

        if (checkerNumber == '') {
            Toast.fail('请选择质检员', 1);
            return;
        } else if (qty <= 0) {
            Toast.fail('请输入打印数量', 1);
            return;
        } else {
            const alertInstance = alert('提示', '您确定要打印' + orderEntry.qty + '个标签么？', [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                { text: '确定打印', onPress: () => this.doPrint() },
            ]);
        }
    };

    /**
     * 显示质检员列表
     */
    showQCSelector = () => {
        this.setState({
            showQCSelector: !this.state.showQCSelector
        });
    }

    /**
     * 选择质检员
     */
    onSelectQCUser = (user) => {
        console.log(user);
        if (user && user.number != '') {
            this.setState({
                showQCSelector: !this.state.showQCSelector,
                checkerNumber: Object.assign(this.state.orderEntry, { 'checkerNumber': user.number })
            });
        }
    }

    /**
     * 打印命令
     */
    doPrint = () => {
        const { orderEntry } = this.state;
        const { billNo = '', materialNumber = '', materialName = '', qty = 0, buildDate = '', supplierNumber = '', checkerNumber = '', printDate = '' } = orderEntry;

        if (checkerNumber == '') {
            Toast.fail('请选择质检员', 1);
            return;
        } else if (qty <= 0) {
            Toast.fail('请输入打印数量', 1);
            return;
        } else {
            //window.android.doPrint(billNo, materialNumber, materialName, qty, moment(buildDate).format(dateFormat), supplierNumber, checkerNumber, printDate);
            window.android.doPrint(billNo, materialNumber, materialName, qty, moment(buildDate).format(dateFormat), supplierNumber, checkerNumber);
        }
    }

    render() {
        const { orderEntry = {}, qcUsers = [], showQCSelector = false } = this.state;
        const { buildDate = '1970-01-01', qty = 0 } = orderEntry;
        let dt = new Date(buildDate);
        let minDate = moment(buildDate).add(-1, 'months')._d;
        let maxDate = moment(buildDate).add(2, 'days')._d;

        return (
            <WingBlank>
                <TopBar title="打印" {...this.props} />
                <List>
                    <List.Item extra={orderEntry.billNo}>单据编号</List.Item>
                    <List.Item extra={orderEntry.materialNumber}>物料编码</List.Item>
                    <List.Item multipleLine>物料名称 <Brief>{orderEntry.materialName}</Brief></List.Item>
                    <List.Item extra={orderEntry.checkerNumber} arrow="horizontal" onClick={this.showQCSelector}>
                        质检员
                    </List.Item>
                    <DatePicker
                        mode="date"
                        title="选择生产日期"
                        extra=""
                        value={dt}
                        format={(value) => { return moment(value).format(dateFormat); }}
                        //minDate={minDate}
                        maxDate={maxDate}
                        onChange={this.onChangeBuildDate}>
                        <List.Item arrow="horizontal">生产日期</List.Item>
                    </DatePicker>
                    <InputItem type="money" placeholder="请输入打印数量" moneyKeyboardAlign="right" value={qty} onChange={this.onChangeQty}>
                        可打印数量
                </InputItem>
                </List>
                <WhiteSpace />
                <WhiteSpace />
                <Button type="primary" style={{ background: '#4D2395' }} icon={<img src="/assets/print_2.svg" alt="" />} onClick={() => this.showAlert()}>打印</Button>
                <Modal
                    popup
                    visible={showQCSelector}
                    style={{ height: "70%" }}
                    onClose={() => this.showQCSelector()}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}>
                    <List renderHeader={() => <div>请选择质检员</div>} className="popup-list">
                        {qcUsers.map((usr, idx) => (
                            <List.Item key={usr.number} onClick={() => this.onSelectQCUser(usr)}>{usr.number}:{usr.name}</List.Item>
                        ))}
                    </List>
                </Modal>
            </WingBlank>)
    }
}