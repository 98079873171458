/**
 * 判断对象是否为空
 * @param {*} obj 
 */
 export function isEmpty(obj) {
    if (obj === undefined || obj === null)
      return true;
    for (var name in obj) {
      return false;
    }
    return true;
  }