import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import App from './App';
import Login from './Login';
import PurchaseOrderList from './components/PurchaseOrder/List';
import PurchaseOrderView from './components/PurchaseOrder/View';
import PurchaseOrderPrint from './components/PurchaseOrder/Print';
import StockList from './components/Stock/List';
import StockPrint from './components/Stock/Print';
import SupplierSelector from './components/Common/SupplierSelector';
import PickOrderList from './components/PickOrder/List';
import PickOrderView from './components/PickOrder/View';
import PickOrderPrint from './components/PickOrder/Print';
import TransferOrderList from './components/TransferOrder/List';
import TransferOrderView from './components/TransferOrder/View';
import TransferOrderPrint from './components/TransferOrder/Print';
import MockOrderPrint from './components/MockOrder/Print';
import MockOrderList from './components/MockOrder/List';

const BasicRoute = () => (
    <HashRouter>
        <Switch>
            <Route exact path="/" component={App} />
            <Route exact path='/Login' component={Login} />
            <Route exact path="/App" component={App} />
            <Route exact path="/PurchaseOrder/List" component={PurchaseOrderList} />
            <Route exact path="/PurchaseOrder/View/:billid/:billno" component={PurchaseOrderView} />
            <Route exact path="/PurchaseOrder/Print/:id" component={PurchaseOrderPrint} />
            <Route exact path="/Stock/List" component={StockList} />
            <Route exact path="/Stock/Print/:id" component={StockPrint} />
            <Route exact path="/MySuppliers" component={SupplierSelector} />
            <Route exact path="/PickOrder/List" component={PickOrderList} />
            <Route exact path="/PickOrder/View/:billid/:billno" component={PickOrderView} />
            <Route exact path="/PickOrder/Print/:id/:billNo" component={PickOrderPrint} />
            <Route exact path="/TransferOrder/List" component={TransferOrderList} />
            <Route exact path="/TransferOrder/View/:billid/:billNo" component={TransferOrderView} />
            <Route exact path="/TransferOrder/Print/:id/:billid" component={TransferOrderPrint} />
            <Route exact path="/MockOrder/Print" component={MockOrderPrint} />
            <Route exact path="/MockOrder/List" component={MockOrderList} />
        </Switch>
    </HashRouter>
);

export default BasicRoute;