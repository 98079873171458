import React from 'react';
import { WingBlank, WhiteSpace, List, Button, DatePicker, InputItem, Modal, Toast } from 'antd-mobile';
import moment, { max } from 'moment';
import request from '../../Utils/request';
import TopBar from './../TopBar';
import { getCurrentSupplierId } from './../../Utils/mySupplier';

const Brief = List.Item.Brief;
const dateFormat = 'YYYY-MM-DD';
const alert = Modal.alert;

/**
 * 采购订单明细打印模板
 */
class PurchaseOrderPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            billNo: '',
            batchNumber: '',
            skuNo: '',
            skuName: '',
            nums: 0,
            buildDate: '',
            supplierCode: '',
            checkerCode: '',

            orderLine: {},
            qcUsers: [],
            showQCSelector: false,
        };
    }

    genData = () => {
        const { id = "0" } = this.props.match.params;
        request({
            url: '/api/services/app/Print/GetPurOrderLine',
            method: 'GET',
            params: { BillId: id }
        }).then(res => {
            //console.log(res);
            //const { qualityInspector = '', qualityInspectorCode = '' } = res.purchaseOrder;

            const { billNo = '', batchNumber = '', supplierCode = '', qualityInspector = '', qualityInspectorCode = '', } = res.purchaseOrder;
            const { buildDate = '', remainStockQty = 0, parcel: { parcelCode = '', parcelName = '', parcelSize = '' } } = res;

            this.setState({
                billNo: billNo,
                batchNumber: batchNumber,
                skuNo: parcelCode,
                skuName: parcelName,
                //nums: remainStockQty,
                //buildDate: buildDate,
                supplierCode: supplierCode,
                checkerCode: qualityInspectorCode,
                qcUsers: [{ "number": qualityInspectorCode, "name": qualityInspector, "id": 52376512 }]
            });
        });
    }

    /**
     * 
     */
    getQCUsers = () => {
        // let supplierId = getCurrentSupplierId();

        // request({
        //     url: '/api/services/app/K3/GetQCUserListByPage',
        //     method: 'GET',
        //     params: { MaxResultCount: 1000, SupplierId: supplierId }
        // }).then(res => {
        //     this.setState({
        //         qcUsers: res.items
        //     });
        // });

        // this.setState({
        //     qcUsers: [{ "number": "001", "name": "测试", "id": 52376512 }],
        // });
    }

    componentDidMount() {
        this.genData();
        //this.getQCUsers();
    }

    /**
     * 修改打印数量
     * @param {*} val 
     */
    onChangeQty = (val) => {
        //console.log(val);

        this.setState({
            //orderLine: Object.assign(this.state.orderLine, { 'remainStockQty': val })
            nums: val,
        });
    }

    /**
     * 选择生产日期
     * @param {*} date 
     */
    onChangeBuildDate = (date) => {
        //console.log(date);
        this.setState({
            //orderLine: Object.assign(this.state.orderLine, { 'buildDate': moment(date).format(dateFormat) })
            //buildDate: moment(date).format(dateFormat)
            buildDate: date,
        });
    }

    /**
     * 
     */
    showAlert = () => {
        // const { orderLine = {} } = this.state;
        // const { parcel = {}, purchaseOrder = {}, remainStockQty = 0, buildDate = '', supplierNumber = '', checkerNumber = '', printDate = '' } = orderLine;

        const { billNo = '', batchNumber = '', skuNo = '', skuName = '', nums = 0, buildDate = '', supplierCode = '', checkerCode = '', qcUsers = [], showQCSelector = false } = this.state;

        if (billNo === '') {
            Toast.fail('采购单号不能为空', 1);
            return;
        } else if (batchNumber == null || batchNumber === '') {
            Toast.fail('批次号不能为空', 1);
            return;
        } else if (buildDate == null || buildDate === '') {
            Toast.fail('生产日期不能为空', 1);
            return;
        }
        else if (checkerCode == null || checkerCode === '') {
            Toast.fail('请选择质检员', 1);
            return;
        } else if (nums <= 0) {
            Toast.fail('请输入打印数量', 1);
            return;
        } else if (nums <= 0) {
            Toast.fail("打印数量必须大于0", 1);
            return;
        } else {
            const alertInstance = alert('提示', '您确定要打印' + nums + '个标签么？', [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                { text: '确定打印', onPress: () => this.doPrint() },
            ]);
        }
    };

    /**
     * 显示质检员列表
     */
    showQCSelector = () => {
        this.setState({
            showQCSelector: !this.state.showQCSelector
        });
    }

    /**
     * 选择质检员
     */
    onSelectQCUser = (user) => {
        //console.log(user);
        if (user && user.number != '') {
            this.setState({
                showQCSelector: !this.state.showQCSelector,
                checkerCode: user.number
                //checkerNumber: Object.assign(this.state.orderLine, { 'checkerNumber': user.number })
            });
        }
    }

    /**
     * 打印命令
     */
    doPrint = () => {
        // const { orderLine } = this.state;
        // const { parcel = {}, purchaseOrder = {}, remainStockQty = 0, buildDate = '', checkerNumber = '', printDate = '' } = orderLine;
        // const { billNo = '', supplierCode = '' } = purchaseOrder;
        // const { parcelCode = '', parcelName = '' } = parcel;

        // console.log(purchaseOrder);
        // console.log(supplierCode);
        // console.log(orderLine);
        // console.log(supplierNumber);
        // console.log(checkerNumber);
        // console.log(remainStockQty);
        // console.log(moment(buildDate).format(dateFormat));

        const { billNo = '', batchNumber = '', skuNo = '', skuName = '', nums = 0, buildDate = '', supplierCode = '', checkerCode = '', qcUsers = [], showQCSelector = false } = this.state;

        if (billNo == null || billNo === '') {
            Toast.fail('请选择采购订单号', 1);
            return;
        } else if (batchNumber === null || batchNumber === '') {
            Toast.fail('批次号不能为空', 1);
            return;
        }
        else if (checkerCode === '') {
            Toast.fail('请选择质检员', 1);
            return;
        } else if (nums <= 0) {
            Toast.fail('请输入打印数量', 1);
            return;
        }
        else {
            //window.android.doPrint(billNo, materialNumber, materialName, remainStockQty, moment(buildDate).format(dateFormat), supplierNumber, checkerNumber, printDate);
            window.android.doPrint(billNo, skuNo, skuName, nums, moment(buildDate).format(dateFormat), supplierCode, checkerCode, batchNumber);
        }
    }

    render() {
        const { billNo = '', batchNumber = '', skuNo = '', skuName = '', nums = 0, buildDate = '', supplierCode = '', checkerCode = '', qcUsers = [], showQCSelector = false } = this.state;
        console.log(buildDate);
        // let dt = new Date(buildDate);
        // let minDate = moment(buildDate).add(-1, 'months')._d;
        // let maxDate = moment(buildDate).add(2, 'days')._d;

        //console.log(buildDate);

        return (
            <WingBlank>
                <TopBar title="采购订单-打印" {...this.props} />
                <List>
                    <List.Item>单据编号<Brief>{billNo}</Brief></List.Item>
                    <List.Item>物料编码<Brief>{skuNo}</Brief></List.Item>
                    <List.Item multipleLine>物料名称 <Brief>{skuName}</Brief></List.Item>
                    <List.Item extra={checkerCode} arrow="horizontal" onClick={this.showQCSelector}>
                        质检员
                    </List.Item>
                    <DatePicker
                        mode="date"
                        title="选择生产日期"
                        extra=""
                        value={buildDate}
                        format={(value) => { return moment(value).format(dateFormat); }}
                        //minDate={minDate}
                        //maxDate={maxDate}
                        onChange={this.onChangeBuildDate}>
                        <List.Item arrow="horizontal">生产日期</List.Item>
                    </DatePicker>
                    <InputItem type="money" placeholder="请输入打印数量" moneyKeyboardAlign="right" value={nums} onChange={this.onChangeQty}>
                        可打印数量
                    </InputItem>
                    <WhiteSpace />
                    <WhiteSpace />
                </List>
                <WhiteSpace />
                <WhiteSpace />
                <Button type="primary" style={{ background: '#4D2395' }} icon={<img src="/assets/print_2.svg" alt="" />} onClick={() => this.showAlert()}>打印</Button>
                <Modal
                    popup
                    visible={showQCSelector}
                    style={{ height: "70%" }}
                    onClose={() => this.showQCSelector()}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}>
                    <List renderHeader={() => <div>请选择质检员</div>} className="popup-list">
                        {qcUsers.map((usr, idx) => (
                            <List.Item key={usr.number} onClick={() => this.onSelectQCUser(usr)}>{usr.number}:{usr.name}</List.Item>
                        ))}
                    </List>
                </Modal>
            </WingBlank>)
    }
}

export default PurchaseOrderPrint;