import React from "react";
import {
  WhiteSpace,
  WingBlank,
  Button,
  Checkbox,
  List,
  ListView,
  Flex,
  SearchBar,
  Modal,
  Toast,
} from "antd-mobile";
import moment from "moment";
import request from "../../Utils/request";
import TopBar from "./../TopBar";
import { getTransferOrderDetail } from "./../../Utils/httpApiHelper";
import { isEmpty } from "../../Utils/utils";

const CheckboxItem = Checkbox.CheckboxItem;
const dateFormat = "YYYY-MM-DD";
const pageSize = 20;
let pageIndex = 1;

/**
 * 调拨单详情
 */
class TransferOrderView extends React.Component {
  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      dataSource,
      isLoading: true,
      hasMore: false,
      dataList: [],
      skuNo: "",
      billNo: "",
      supplierCode: "",
      checkerCode: "",
      printList: [],
      confirmPrintShow: false,
    };
  }

  genData(pIndex = 1) {
    const { billid = "0", billno = "" } = this.props.match.params;
    const { skuNo = "" } = this.state;

    //console.log(this.props.match.params);
    getTransferOrderDetail({ id: billid, parcelCode: skuNo }).then((res) => {
      const list = this.state.dataList.concat(res.orderLineList);

      const printList = res.orderLineList.map((line) => {
        return {
          billNo: res.orderCode,
          skuNo: line.thingBO.thingCode,
          skuName: line.thingBO.thingName,
          nums: line.transferQty,
          buildDate: moment(new Date()).format(dateFormat),
          supplierCode: res.wmsSupplierBO.supplierCode,
          checkerCode: "001",
          batchNumber: res.batchNumber,
        };
      });

      this.setState({
        id: billid,
        billNo: billno,
        printList: printList,
        //supplierCode: res.supplierBO.supplierCode,
        hasMore: res.totalCount > pageIndex * pageSize,
        dataList: list,
        dataSource: this.state.dataSource.cloneWithRows(list),
        isLoading: false,
      });
    });
  }

  /**
   * 页面加载时
   */
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.genData();
  }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    }

    if (this.state.hasMore === false) {
      return;
    }

    //console.log('reach end', event);
    this.setState({ isLoading: true });
    this.genData(++pageIndex);
  };

  /**
   * 打印模板
   * @param {*} row
   */
  onPrint = (row) => {
    this.props.history.push(
      "/TransferOrder/Print/" + row.id + "/" + this.state.id
    );
  };

  /**
   *
   * @param {*} value
   */
  onSearch = (value) => {
    const that = this;
    pageIndex = 1;

    this.setState(
      {
        skuNo: value,
        dataList: [],
      },
      function () {
        that.genData();
      }
    );
  };

  onPickSku = (val, e) => {
    const { checked = false } = e.target;
    const {
      printList = [],
      billNo = "",
      supplierCode = "",
      checkerCode = "",
    } = this.state;

    // console.log(checked);
    //console.log(val);

    const newData = [...printList];
    const index = newData.findIndex(
      (item) => item.skuNo === val.parcel.parcelCode
    );
    const item = newData[index];

    // console.log(item);
    // console.log(val);
    if (checkerCode === "") {
      Toast.fail("供应商质检员信息不能为空");
    } else {
      if (checked === true) {
        if (isEmpty(item)) {
          newData.push({
            billNo: billNo,
            skuNo: val.parcel.parcelCode,
            skuName: val.parcel.parcelName,
            nums: val.pickQty,
            buildDate: moment(new Date()).format(dateFormat),
            supplierCode: supplierCode,
            checkerCode: checkerCode,
            batchNumber: val.batchNumber,
          });
        } else {
          if (isNaN(parseInt(val.pickQty)) === false)
            item.nums += parseInt(val.pickQty);
        }
      } else if (checked === false && isEmpty(item) === false) {
        newData.splice(index, 1);
      }

      this.setState({
        printList: newData,
      });
    }
  };

  onBatchPrint = () => {
    const { printList = [] } = this.state;

    //console.log(printList);

    if (printList && printList.length > 0) {
      // printList.forEach((val, idx) => {
      //     console.log(val, idx);
      // });

      this.setState({
        confirmPrintShow: true,
      });
    } else {
      Toast.fail("请选择需要打印的包件");
    }
  };

  onBatchPrintClose = () => {
    this.setState({
      confirmPrintShow: false,
    });
  };

  onDoBatchPrint = () => {
    const { printList = [] } = this.state;

    console.log(printList);

    if (printList && printList.length > 0) {
      printList.forEach((item, idx) => {
        window.android.doPrint3(
          item.billNo,
          item.skuNo,
          item.skuName,
          item.nums,
          item.buildDate,
          item.supplierCode,
          item.checkerCode,
          item.batchNumber
        );
      });
    }
  };

  render() {
    const { confirmPrintShow = false, printList = [] } = this.state;

    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: "#F5F5F9",
          height: 8,
          borderTop: "1px solid #ECECED",
          borderBottom: "1px solid #ECECED",
        }}
      />
    );

    const row = (rowData, sectionID, rowID) => {
      //console.log(rowData);
      return (
        <div key={rowID} style={{ padding: "5px 15px" }} item={rowData}>
          <div
            style={{
              display: "-webkit-box",
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 0",
            }}
          >
            <div style={{ lineHeight: 1 }}>
              {/* <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                提货明细号：{rowData.transferOrderLineCode}
              </div> */}
              <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                包件编码：{rowData.thingBO.thingCode}
              </div>
              <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                包件名称：{rowData.thingBO.thingName}
              </div>
              <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                规格：{rowData.thingBO.size}
              </div>
              <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                调拨数量:{rowData.transferQty}
              </div>
            </div>
            {/* <div style={{ align: "center" }}>
            </div> */}
          </div>
          <div>
            <Button
              icon="check"
              type="primary"
              size="small"
              onClick={() => this.onPrint(rowData)}
            >
              打印
            </Button>
          </div>
        </div>
      );
    };

    //console.log(printList);

    return (
      <WingBlank style={{ padding: "5px 5px" }}>
        <TopBar title={"调拨单详情-" + this.state.billNo} {...this.props} />
        <SearchBar placeholder="请输入包件编码" onSubmit={this.onSearch} />
        <WhiteSpace />
        <Button type="warning" onClick={() => this.onBatchPrint()}>
          整单打印
        </Button>
        <WhiteSpace />
        <ListView
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          // renderHeader={() => <span></span>}
          renderFooter={() => (
            <div style={{ padding: 30, textAlign: "center" }}>
              {this.state.isLoading ? "Loading..." : "我是有底线的"}
            </div>
          )}
          renderRow={row}
          renderSeparator={separator}
          className="am-list"
          pageSize={20}
          useBodyScroll
          onScroll={() => {
            console.log("scroll");
          }}
          scrollRenderAheadDistance={500}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10}
        />
        <WhiteSpace />
        <Modal
          popup
          visible={confirmPrintShow}
          animationType="slide-up"
          title="打印预览"
          afterClose={() => {
            console.log("afterClose");
          }}
        >
          <List
            className="popup-list"
            style={{ height: 600, overflow: "scroll" }}
          >
            {printList.map((item, index) => (
              <List.Item multipleLine key={index} extra={index+1}>
                单据编号：{item.billNo}
                <List.Item.Brief>
                  包件编码：{item.skuNo}
                  <br />
                  包件名称：{item.skuName}
                  <br />
                  打印数量：{item.nums}
                  {/* <br />
                  批次号：{item.batchNumber} */}
                  <br />
                  生产日期：{item.buildDate}
                  <br />
                  质检员：{item.checkerCode}
                </List.Item.Brief>
              </List.Item>
            ))}
            <List.Item>
              <Flex>
                <Flex.Item>
                  <Button type="primary" onClick={() => this.onDoBatchPrint()}>
                    确认打印
                  </Button>
                </Flex.Item>
                <Flex.Item>
                  <Button
                    type="warning"
                    onClick={() => this.onBatchPrintClose()}
                  >
                    关闭
                  </Button>
                </Flex.Item>
              </Flex>
            </List.Item>
          </List>
        </Modal>
      </WingBlank>
    );
  }
}

export default TransferOrderView;
