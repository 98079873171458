const key = "duoyi.factory.mysuppliers";
const my = "duoyi.factory.current.supplierId";
const supplierCodeKey = "duoyi.factory.current.supplierCode";

/**
 * get my suppliers from localstorage 
 */
export function fetchMySuppliers() {
    let suppliers;

    const jsonString = localStorage.getItem(key);

    try {
        suppliers = JSON.parse(jsonString);
    } catch (e) {
        suppliers = jsonString;
    }

    if (suppliers === null) {
        suppliers = [];
    } else if (typeof suppliers === 'string') {
        return [suppliers];
    }

    return suppliers;
}

/**
 * set my suppliers data to localstorage
 */
export function updateMySuppliers(supliers) {
    localStorage.setItem(key, JSON.stringify(supliers));
}

// /**
//  * 
//  * @param {*} supplierid 
//  */

// export function setCurrentSupplierId(supplierid) {
//     localStorage.setItem(my, supplierid);
// }

// /**
//  * 
//  */
// export function getCurrentSupplierId() {
//     let supplierId = 0;
//     if (parseInt(localStorage.getItem(my))) {
//         supplierId = parseInt(localStorage.getItem(my));
//     }
//     return supplierId;
// }

/**
 * 
 * @param {*} supplierCode 
 */
export function setSupplierCode(supplierCode) {
    localStorage.setItem(supplierCodeKey, supplierCode);
}

/**
 * 
 * @returns 
 */
export function getSupplierCode()
{
    return localStorage.getItem(supplierCodeKey);
}

/**
 * clear my suppliers from localstorage
 */
export function clear() {
    localStorage.removeItem(key);
}