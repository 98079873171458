import React from 'react';
import { Flex, WhiteSpace, WingBlank, InputItem, Button, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import request from './Utils/request';
// import { fetchMySuppliers, updateMySuppliers, setCurrentSupplierId, getCurrentSupplierId, clear } from './Utils/mySupplier';
//import { getMySuppliersFromLocal, } from './Utils/httpApiHelper';

import './Login.css';

/**
 * 用户登录
 */
class Login extends React.Component {
    componentDidMount() {
        localStorage.clear();
    }

    // getMySuppliers = () => {
    //     let mySuppliers = fetchMySuppliers();
    //     let supplierId = getCurrentSupplierId();
    //     let nums = mySuppliers.length;

    //     if (supplierId <= 0) {
    //         if (mySuppliers === null || nums == 0) {
    //             getMySuppliersFromLocal().then(res => {
    //                 console.log(res);
    //                 mySuppliers = res;

    //                 updateMySuppliers(res);

    //                 this.autoHereWeGo(mySuppliers);
    //             });
    //         } else {
    //             this.autoHereWeGo(mySuppliers);
    //         }
    //     }
    // }

    /**
     * 跳转
     * @param {*} suppliers 
     */
    autoHereWeGo = (suppliers) => {
        // const nums = suppliers.length;

        // if (nums === 0) {
        // } else if (nums === 1) {
        //     setCurrentSupplierId(suppliers[0].supplierId);
        //     this.props.history.push('/App');
        // } else if (nums > 1) {
        //     this.props.history.push('/MySuppliers');
        // }

        this.props.history.push('/App');
    }

    /**
     * 登录提交
     */
    onSubmit = () => {
        let that = this;

        that.props.form.validateFields({ force: true }, (error) => {
            if (!error) {
                const { userName = '', password = '' } = this.props.form.getFieldsValue();
                //console.log(this.props.form.getFieldsValue());

                request('/api/TokenAuth/Authenticate', {
                    method: 'POST',
                    data: {
                        "UserNameOrEmailAddress": userName,
                        "Password": password
                    }
                }).then(res => {
                    //console.log(res);
                    localStorage.setItem('duoyi.factory.token', res.accessToken);

                    Toast.hide();
                    //that.getMySuppliers();
                    that.autoHereWeGo();
                });

            } else {
                Toast.fail('输入参数校验错误', 1);
            }
        });
    }

    render() {
        const { getFieldProps, getFieldError } = this.props.form;
        return (
            <WingBlank >
                <form>
                    <Flex justify="center" style={{ marginTop: "150px" }}>
                        <img src="assets/logo_2.png" style={{ width: "40%" }} />
                    </Flex>
                    <WhiteSpace />
                    <WhiteSpace />
                    <InputItem style={{ color: '#ffffff' }} {...getFieldProps('userName', {
                        rules: [
                            { required: true, message: '请输入登录名' },
                            { validator: this.validateAccount },
                        ],
                    })}
                        clear
                        error={!!getFieldError('userName')}
                        onErrorClick={() => {
                            Toast.fail(getFieldError('userName').join('、'));
                        }}
                        placeholder="请输入登录名"></InputItem>
                    <WhiteSpace />
                    <InputItem
                        style={{ color: '#ffffff' }}
                        {...getFieldProps('password', {
                            rules: [
                                { required: true, message: '密码不合法' },
                                { validator: this.validateAccount },
                            ],
                        })}
                        clear
                        error={!!getFieldError('password')}
                        onErrorClick={() => {
                            Toast.fail(getFieldError('password').join('、'));
                        }}
                        type="password" placeholder="请输入密码"></InputItem>
                    <WhiteSpace />
                    <WhiteSpace />
                    <Button type="primary" style={{ background: "#6439af" }} onClick={this.onSubmit}>登录</Button>
                </form>
            </WingBlank>
        )
    }
}

export default createForm()(Login);