import React from "react";
import {
  WingBlank,
  WhiteSpace,
  List,
  Button,
  DatePicker,
  InputItem,
  Modal,
  Toast,
} from "antd-mobile";
import moment, { max } from "moment";
import TopBar from "./../TopBar";
import { getTransferOrderLineDetail } from "./../../Utils/httpApiHelper";

const Brief = List.Item.Brief;
const dateFormat = "YYYY-MM-DD";
const alert = Modal.alert;

/**
 * 调拨单明细打印模板
 */
class TransferOrderPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billNo: "",
      batchNumber: "",
      skuNo: "",
      skuName: "",
      nums: 0,
      buildDate: new Date(),
      supplierCode: "",
      checkerCode: "",

      remainStockQty: 0,
      //orderLine: {},
      qcUsers: [],
      showQCSelector: false,
    };
  }

  genData = () => {
    const { id = "0", billid = "" } = this.props.match.params;

    getTransferOrderLineDetail({
      id: id,
      orderId: billid,
    }).then((res) => {
      //const { supplierBO: { supplierCode = '', qualityInspector = '', qualityInspectorCode = '' }, } = res.pickOrder;
      //const { pickQty = 0, pickOrderLineCode = '', batchNumber = '', parcel: { parcelCode = '', parcelName = '', parcelSize = '' } } = res;

      const { transferQty = 0, orderBO = {} } = res;

      this.setState({
        billNo: orderBO?.orderCode,
        batchNumber: orderBO?.batchNumber,
        skuNo: res.thingBO.thingCode,
        skuName: res.thingBO.thingName,
        nums: res.transferQty,
        supplierCode: orderBO?.wmsSupplierBO.supplierCode,
        checkerCode: "001",
        qcUsers: [{ number: "001", name: "001", id: 52376512 }],
      });
    });
  };

  componentDidMount() {
    this.genData();
  }

  /**
   * 修改打印数量
   * @param {*} val
   */
  onChangeQty = (val) => {
    //console.log(val);

    this.setState({
      nums: val,
    });
  };

  /**
   * 选择生产日期
   * @param {*} date
   */
  onChangeBuildDate = (date) => {
    //console.log(date);
    this.setState({
      //buildDate: moment(date).format(dateFormat)
      buildDate: date,
    });
  };

  /**
   *
   */
  showAlert = () => {
    const {
      billNo = "",
      batchNumber = "",
      skuNo = "",
      skuName = "",
      nums = 0,
      buildDate = "",
      supplierCode = "",
      checkerCode = "",
      qcUsers = [],
      showQCSelector = false,
    } = this.state;

    if (checkerCode === "") {
      Toast.fail("请选择质检员", 1);
      return;
    } else if (batchNumber === "") {
      Toast.fail("批次号不能为空", 1);
      return;
    } else if (checkerCode === "") {
      Toast.fail("请选择质检员", 1);
      return;
    } else if (nums <= 0) {
      Toast.fail("请输入打印数量", 1);
      return;
    } else if (nums <= 0) {
      Toast.fail("打印数量必须大于0", 1);
      return;
    } else {
      const alertInstance = alert(
        "提示",
        "您确定要打印" + nums + "个标签么？",
        [
          {
            text: "取消",
            onPress: () => console.log("cancel"),
            style: "default",
          },
          { text: "确定打印", onPress: () => this.doPrint() },
        ]
      );
    }
  };

  /**
   * 显示质检员列表
   */
  showQCSelector = () => {
    this.setState({
      showQCSelector: !this.state.showQCSelector,
    });
  };

  /**
   * 选择质检员
   */
  onSelectQCUser = (user) => {
    //console.log(user);
    if (user && user.number != "") {
      this.setState({
        showQCSelector: !this.state.showQCSelector,
        checkerCode: user.number,
        //checkerNumber: Object.assign(this.state.orderLine, { 'checkerNumber': user.number })
      });
    }
  };

  /**
   * 打印命令
   */
  doPrint = () => {
    const {
      billNo = "",
      batchNumber = "",
      skuNo = "",
      skuName = "",
      nums = 0,
      buildDate = "",
      supplierCode = "",
      checkerCode = "",
      qcUsers = [],
      showQCSelector = false,
    } = this.state;

    if (billNo === "") {
      Toast.fail("调拨单号不能为空", 1);
      return;
    } else if (batchNumber == null || batchNumber === "") {
      Toast.fail("批次号不能为空", 1);
      return;
    } else if (buildDate == null || buildDate === "") {
      Toast.fail("生产日期不能为空", 1);
      return;
    } else if (skuNo === "") {
      Toast.fail("包件编码不能为空", 1);
    } else if (checkerCode == null || checkerCode === "") {
      Toast.fail("请选择质检员", 1);
      return;
    } else if (nums <= 0) {
      Toast.fail("请输入打印数量", 1);
      return;
    } else {
      //window.android.doPrint(billNo, materialNumber, materialName, remainStockQty, moment(buildDate).format(dateFormat), supplierNumber, checkerNumber, printDate);
      window.android.doPrint3(
        billNo,
        skuNo,
        skuName,
        nums,
        moment(buildDate).format(dateFormat),
        supplierCode,
        checkerCode,
        batchNumber
      );
    }
  };

  render() {
    const {
      billNo = "",
      skuNo = "",
      skuName = "",
      nums = 0,
      buildDate = "",
      supplierCode = "",
      checkerCode = "",
      qcUsers = [],
      showQCSelector = false,
    } = this.state;

    // let dt = new Date(buildDate);

    // let minDate = moment(buildDate).add(-1, 'months')._d;
    // let maxDate = moment(buildDate).add(2, 'days')._d;

    // if (buildDate <= '2000-01-01') {
    //     dt = new Date();
    //     minDate = moment().add(-1, 'months')._d;
    //     maxDate = moment().add(2, 'days')._d;
    // }

    //console.log(minDate);
    //console.log(maxDate);
    //console.log(orderLine);
    return (
      <WingBlank>
        <TopBar title="提货单-打印" {...this.props} />
        <List>
          <List.Item>
            单据编号<Brief>{billNo}</Brief>
          </List.Item>
          <List.Item>
            包件编码<Brief>{skuNo}</Brief>
          </List.Item>
          <List.Item multipleLine>
            包件名称 <Brief>{skuName}</Brief>
          </List.Item>
          <List.Item
            extra={checkerCode}
            arrow="horizontal"
            onClick={this.showQCSelector}
          >
            质检员
          </List.Item>
          <DatePicker
            mode="date"
            title="选择生产日期"
            extra=""
            value={buildDate}
            format={(value) => {
              return moment(value).format(dateFormat);
            }}
            onChange={this.onChangeBuildDate}
          >
            <List.Item arrow="horizontal">生产日期</List.Item>
          </DatePicker>
          <InputItem
            type="money"
            placeholder="请输入打印数量"
            moneyKeyboardAlign="right"
            value={nums}
            onChange={this.onChangeQty}
          >
            可打印数量
          </InputItem>
          <WhiteSpace />
          <WhiteSpace />
        </List>
        <WhiteSpace />
        <WhiteSpace />
        <Button
          type="primary"
          style={{ background: "#4D2395" }}
          icon={<img src="/assets/print_2.svg" alt="" />}
          onClick={() => this.showAlert()}
        >
          打印
        </Button>
        <Modal
          popup
          visible={showQCSelector}
          style={{ height: "70%" }}
          onClose={() => this.showQCSelector()}
          animationType="slide-up"
          afterClose={() => {
            console.log("afterClose");
          }}
        >
          <List
            renderHeader={() => <div>请选择质检员</div>}
            className="popup-list"
          >
            {qcUsers.map((usr, idx) => (
              <List.Item
                key={usr.number}
                onClick={() => this.onSelectQCUser(usr)}
              >
                {usr.number}:{usr.name}
              </List.Item>
            ))}
          </List>
        </Modal>
      </WingBlank>
    );
  }
}

export default TransferOrderPrint;
