import React from 'react';
import { WingBlank, ListView, SearchBar } from 'antd-mobile';
import moment from 'moment';
import request from '../../Utils/request';
import TopBar from './../TopBar';

const dateFormat = 'YYYY-MM-DD';
const pageSize = 20;
let pageIndex = 1;

/**
 * 采购订单详情
 */
class PurchaseOrderView extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            hasMore: false,
            dataList: [],
            skuNo: '',
            billNo: '',
        };
    }

    genData(pIndex = 1) {
        const { billid = "0", billno = '' } = this.props.match.params;
        const { skuNo = '' } = this.state;

        request({
            url: '/api/services/app/Print/GetPurchaseOrder',
            method: 'GET',
            params: {
                SkipCount: (pIndex - 1) * pageSize,
                MaxResultCount: pageSize,
                //BillId: billid,
                BillNo: billno,
                MaterialNumber: skuNo,
            }
        }).then(res => {
            const list = this.state.dataList.concat(res.orderLines);
            this.setState({
                billNo: billno,
                hasMore: res.totalCount > (pageIndex * pageSize),
                dataList: list,
                dataSource: this.state.dataSource.cloneWithRows(list),
                isLoading: false,
            })
        });
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this.genData();
    }

    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }

        if (this.state.hasMore === false) {
            return;
        }

        console.log('reach end', event);
        this.setState({ isLoading: true });
        this.genData(++pageIndex);
    }

    /**
     * 打印模板
     * @param {*} row 
     */
    onPrint = (row) => {
        this.props.history.push('/PurchaseOrder/Print/' + row.id);
    }

    /**
     * 
     * @param {*} value 
     */
    onSearch = (value) => {
        const that = this;
        pageIndex = 1;

        this.setState({
            skuNo: value,
            dataList: []
        }, function () {
            that.genData();
        });
    }

    render() {
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 8,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );

        const row = (rowData, sectionID, rowID) => {
            return (
                <div key={rowID} style={{ padding: '0 15px' }} item={rowData} onClick={() => this.onPrint(rowData)}>
                    <div style={{ display: '-webkit-box', display: 'flex', justifyContent: 'space-between', padding: '15px 0' }}>
                        <div style={{ lineHeight: 1 }}>
                            <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>物料编码：{rowData.parcel.parcelCode}</div>
                            <div style={{ marginBottom: '8px', }}>物料名称：{rowData.parcel.parcelName}</div>
                            <div style={{ marginBottom: '8px', }}>规格：{rowData.parcel.parcelSize}</div>
                            <div style={{ marginBottom: '8px', }}>采购/入库/提货/关闭：{rowData.purchaseQty}/{rowData.stockInQty}/{rowData.pickingQty}/{rowData.closedQty}</div>
                            <div style={{ marginBottom: '8px', }}>剩余数量：{rowData.remainStockQty}</div>
                            {/* <div style={{ marginBottom: '8px', }}>交期时间：{moment(rowData.deliveryDate).format(dateFormat)}</div> */}
                        </div>
                    </div>
                </div>
            );
        };
        return (
            <WingBlank>
                <TopBar title={"采购订单-" + this.state.billNo} {...this.props} />
                <SearchBar placeholder="请输入物料编码" onSubmit={this.onSearch} />
                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    // renderHeader={() => <span></span>}
                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? 'Loading...' : '我是有底线的'}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={20}
                    useBodyScroll
                    onScroll={() => { console.log('scroll'); }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </WingBlank>
        )
    }
}

export default PurchaseOrderView;